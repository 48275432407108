<template>
	<div class="page_content">
		<div class="page_edit_content">
			<Form ref="form" @handleChange="handleChange" @handleSubmit="handleSubmit" @handleReset="handleReset"
				:form_data="form_data">
				<div slot="department">
					<a @click="add_department()">
						<a-icon type="plus-circle" />
						<span style="padding-left: 5px">添加部门</span>
					</a>
					<a-input type="hidden" name="department_data" v-decorator="['department_data', department_data]" />
					<div>
						<div class="department" v-for="(item, index) in department" :key="index">
							<div class="department_item">
								<div>
									部门:
									<span style="color: #1890ff">{{
                                        item.department_name
                                    }}</span>
								</div>
								<div>
									岗位职务:
									<span style="color: #1890ff">{{
                                        item.role_name
                                    }}</span>
								</div>
							</div>
							<a-divider type="vertical" />
							<div class="operson_box">
                                <a @click="edit_department(item,index)" class="time_del">编辑</a>
							    <a @click="del_department(item)" class="time_del">删除</a>
                            </div>
						</div>
					</div>
				</div>
				<div slot="wx">
					<div v-if="info.applets.nickname">
						{{ info.applets.nickname }}
						<a-divider type="vertical" />
						<a-popconfirm title="您确定要解绑吗?" v-auth="'delete'" @confirm="untie_wx_mini">
							<a>解绑</a>
						</a-popconfirm>
					</div>
					<div v-else>
						无
					</div>
				</div>
			</Form>
		</div>
		<EditPop ref="edit" @tree_select="tree_select" @get_select_value="get_select_value" @handleSubmit="submit"
			:form_data="form_data_seo" :visible.sync="visible"></EditPop>
	</div>
</template>

<script>
	import EditPop from "@/components/EditPop";
	import Form from "@/components/Form";
	import {
		getUserData,
		modifyUser,
		getRule,
		getAuthList,
		cancelBinding,
		category
	} from "@/api/user";
	import {
		Modal
	} from 'ant-design-vue';
	// import { try } from 'q';

	export default {
		components: {
			Form,
			EditPop,
		},
		data() {
			return {
                index: -1 ,//编辑时使用
				visible: false,
				info: {
					applets: {

					}
				},
				role_list: [],
				form_data: {
					title: "添加员工",
					show_submit_btn: true,
					show_close_btn: true,
					close_reset_btn: true,
					list: [{
							type: "text",
							name: "id",
							hidden: true,
							options: {
								initialValue: ""
							}
						},
						{
							type: "text",
							name: "username",
							title: "姓名",
							options: {
								rules: [{
									required: true,
									message: "请输入姓名"
								}],
								initialValue: ""
							}
						},
						{
							type: "text",
							name: "drp_coding",
							title: "工号",
							placeholder: "请输入DPR编号",
							options: {
								initialValue: ""
							}
						},
						{
							type: "radio",
							name: "status",
							title: "状态",
							// defaultValue:1,
							// relation_name:'leave_reason',
							options: {
								rules: [{
									required: true,
									message: "请选择状态"
								}],
								initialValue: '1'
							},
							list: [{
									key: "1",
									value: "在职"
								},
								{
									key: "-1",
									value: "离职"
								}
							]
						},
						// {
						//     type: "text",
						// 	name: "leave_reason",

						//     title: "离职原因",
						//     close: true,
						//     options: {
						//         rules: [
						//             {
						//                 required: true,
						//                 message: "请填写离职原因"
						//             }
						//         ],
						//         initialValue: ""
						//     }
						// },
						{
							type: "radio",
							name: "sex",
							title: "性别",
							options: {
								rules: [{
									required: true,
									message: "请填写性别"
								}],
								initialValue: ''
							},
							list: [{
									key: "1",
									value: "男"
								},
								{
									key: "2",
									value: "女"
								}
							]
						},
						{
							type: "slot",
							name: "department",
							title: "部门",
							options: {
								rules: [{
									required: true,
									message: "请添加部门"
								}],
								// initialValue:{
								//   key:'',
								//   value:''
								// }
							},
						},
						{
							type: "radio",
							name: "is_leader",
							title: "部门领导",
							options: {
								rules: [{
									required: true,
									message: "请选择是否部门领导"
								}],
								initialValue: '0'
							},
							list: [{
									key: "1",
									value: "是"
								},
								{
									key: "0",
									value: "否"
								}
							]
						},
						{
							type: "select",
							name: "auth_id",
							title: "角色",
							mode: "multiple", //多选 multiple
							options: {
								initialValue: '',
								rules: [{
									required: true,
									message: "请选择角色"
								}],
							},
							list: []
						},
						{
							type: "radio",
							name: "is_approve",
							title: "是否为审批人",
							// defaultValue:1,
							// relation_name:'leave_reason',
							options: {
								rules: [{
									required: false,
									message: "请选择状态"
								}],
								initialValue: '0'
							},
							list: [{
									key: "1",
									value: "是"
								},
								{
									key: "0",
									value: "否"
								}
							]
						},
						{
							type: "radio",
							name: "is_attendance",
							title: "是否考勤",
							options: {
								rules: [{
									required: true,
									message: "请选择是否考勤打卡"
								}],
								initialValue: '1'
							},
							list: [{
									key: "1",
									value: "是"
								},
								{
									key: "0",
									value: "否"
								}
							]
						},
						{
							type: "select",
							name: "rule_id",
							title: "考勤规则",
							options: {
								rules: [{
									required: true,
									message: "请选择考勤规则"
								}],
							},
							list: [

							]
						},


						{
							type: "radio",
							name: "is_daily",
							title: "日报填写",
							options: {
								rules: [{
									required: true,
									message: "请选择是否日报填写"
								}],
								initialValue: '1'
							},
							list: [{
									key: "1",
									value: "是"
								},
								{
									key: "0",
									value: "否"
								}
							]
						},
						{
							type: "radio",
							name: "is_admin",
							title: "管理员",
							options: {
								rules: [{
									required: true,
									message: "请选择是否管理员"
								}],
								initialValue: '0'
							},
							list: [{
									key: "1",
									value: "是"
								},
								{
									key: "0",
									value: "否"
								}
							]
						},
						{
							type: "text",
							name: "id_card",
							title: "身份证",
							options: {
								rules: [{
									required: true,
									message: "请输入身份证号"
								}],
								initialValue: ""
							}
						},
						{
							type: "text",
							name: "phone",
							title: "账号",
							placeholder: "公司手机号/其他",
							options: {
								rules: [{
									required: true,
									message: "请输入账号"
								}],
								initialValue: ""
							}
						},
						{
							type: "text",
							name: "password",
							title: "密码",
							placeholder: "初始密码888888",
							options: {
								initialValue: ""
							}
						},
						{
							type: "text",
							name: "mobile",
							title: "手机号",
							placeholder: "私人手机号",
							options: {
								initialValue: ""
							}
						},
						{
							type: "radio",
							name: "is_safe_login",
							title: "手机绑定",
							tooltip: "注：是否绑定的手机型号登录",
							options: {
								rules: [{
									required: true,
									message: "请选择是否绑定手机"
								}],
								initialValue: ''
							},
							list: [{
									key: "1",
									value: "是"
								},
								{
									key: "0",
									value: "否"
								}
							]
						},
						{
							type: "text",
							name: "imei",
							title: "IMEI",
							options: {
								initialValue: ""
							}
						},
						{
							type: "text",
							name: "device_id",
							title: "设备ID",
							options: {
								initialValue: ""
							}
						},
						{
							type: "text",
							name: "qq",
							title: "QQ",
							options: {
								initialValue: ""
							}
						},
						{
							type: "text",
							name: "email",
							title: "邮箱地址",
							options: {
								initialValue: ""
							}
						},
						{
							type: "select",
							name: "area",
							title: "人员类型",
							mode: "default",
							options: {
								initialValue: ''
							},
							list: [{
									key: "市区",
									value: "市区"
								},
								{
									key: "县郊",
									value: "县郊"
								},
								{
									key: "乡镇",
									value: "乡镇"
								}
							]
						},
						{
							type: "date",
							name: "entry_time",
							title: "入职日期",
							options: {

							}
						},
						{
							type: "date",
							name: "become_time",
							title: "转正时间",
							options: {

							}
						},
						{
							type: "date",
							name: "leave_time",
							title: "离职日期",
							options: {

							}
						},
						{
							type: "text",
							name: "address",
							title: "家庭住址",
							options: {
								initialValue: ""
							}
						},
						// {
						//   type: "upload",
						//   name: "headimg",
						//   title: "头像",
						//   options: {

						// },
						{
							type: "upload",
							name: "image",
							title: "人脸图片",
							options: {
								initialValue: []
							}
						},
						{
							type: "text",
							name: "remark",
							title: "备注",
							options: {
								initialValue: ""
							}
						},
						{
							type: "slot",
							hidden: !this.$route.query.id,
							name: "wx",
							title: "绑动销小程序",
							options: {
								initialValue: ""
							}
						},
						// {
						//     type: "text",
						//     name: "openid",
						//     title: "微信openID",
						//     options: {
						//         initialValue: ""
						//     }
						// }

					]
				},
				department: [],
				department_data: {
					rules: [{
						required: true,
						message: "请添加部门"
					}]
				},
				name: "",
				form_data_seo: {
					title: "添加部门",
					list: [{
							type: "tree-select",
							name: "department_id",
							title: "部门",
							options: {
								rules: [{
									required: true,
									message: "请添加部门"
								}]
							},
							treeData: [],
							multiple: false
						},
						{
							type: "tree-select",
							name: "role_id",
							title: "岗位职务",
							options: {
								rules: [{
									required: true,
									message: "请选择岗位职务"
								}]
							},
							treeData: [],
							multiple: false
						},
						// {
						//     type: "select",
						//     name: "role_id",
						//     title: "岗位职务",
						//     mode: "default",
						//     options: {
						//         rules: [
						//             {
						//                 required: true,
						//                 message: "请选择岗位职务"
						//             }
						//         ]
						//     },
						//     list: []
						// }
					]
				},
			};
		},
		async created() {
			let uid = this.$router.currentRoute.query.id;
			if (uid) {
				this.get_list();

			}

			// getRule().then(res => {
			//     this.form_data.list.forEach(item => {
			//         if (item.name == "rule_id") {
			//             item.list = res.data.list.map((item) => {
			//                 let val = {};
			//                 val.key = item.id;
			//                 val.value = item.rule_name
			//                 return val
			//             });
			//         }
			//     });
			// })
			category({
				data: {
					type: 'all'
				}
			}).then(res => {
				this.form_data.list.forEach(item => {
					if (item.name == "rule_id") {
						item.list = res.data.group.map((item) => {
							let val = {};
							val.key = item.id;
							val.value = item.title
							return val
						});
					}
				});
			})
			getAuthList().then(res => {
				this.form_data.list.forEach(item => {
					if (item.name == "auth_id") {
						item.list = res.data.list.map((item) => {
							let val = {};
							val.key = item.id;
							val.value = item.title
							return val
						});
					}
				});
			})

			this.$method.get_department().then(res => {
				this.form_data_seo.list.forEach(item => {
					if (item.name == "department_id") {
						item.treeData = res;
					}
				});
			});
			this.$method.get_role_tree().then(res => {
				this.form_data_seo.list.forEach(item => {
					if (item.name == "role_id") {
						item.treeData = res;
					}
				});
			});
			// this.$method.get_role().then(res => {
			//     this.form_data_seo.list.forEach(item => {
			//         if (item.name == "role_id") {
			//             item.list = res;
			//         }
			//     });
			// });
		},
		methods: {
			async get_list(e) {
				let id = this.$route.query.id;
				try {
					await getUserData({
						uid: id || ""
					}).then(res => {
						let list = res.data;
						this.info = res.data
						this.form_data.list.forEach(item => {
							for (let vo in list) {
								if (item.name == vo) {
									if (item.type == "date") {
										if (list[vo] != null) {
											if (list[vo]) item.options.initialValue = this.$moment(
												list[vo], 'YYYY/MM/DD')

										}

									} else if (item.type == 'select') {
										this.$refs.form.setFieldsValue({
											[item.name]: list[vo]
										})
									} else if (item.type == 'upload') {
										item.options.initialValue = list[item.name] ? [{
											uid: list[item.name],
											name: list[item.name],
											status: 'done',
											url: list[item.name]
										}] : [];
									} else {
										if (list[vo] != null) {
											item.options.initialValue = list[vo].toString();
										}

									}
								} else if (item.type == "slot" && item.name == 'department') {

									let data = list.department_data
									let select = []
									data.forEach(io => {
										select.push({
											department_id: io.id,
											department_name: io.name,
											role_id: io.role_data.id,
											role_name: io.role_data.name,
										})
									})
									this.department = select
									this.$refs.form.setFieldsValue({
										department_data: JSON.stringify(this.department)
									})
								}
							}
						});
					});
				} catch {}
				// let list =
			},
			tree_select(e) {
				// this.name = e[0];
			},
			add_department() {
                this.index = -1
                this.form_data_seo.title = '添加部门'
                this.form_data_seo.list.forEach(item=>{
                    if(item.name == 'department_id'){
                        delete item.options.initialValue
                    }else if(item.name == 'role_id'){
                        delete item.options.initialValue
                    }
                })
				this.visible = true;
			},
			get_select_value(e) {
				this.form_data_seo.list.forEach(item => {
					if (item.name == "role_id") {
						item.list.forEach(vo => {
							if (vo.key == e) {}
						});
					}
				});
			},
			del_department(params) {
				this.department.forEach((item, index) => {
					if (params == item) {
						this.department = this.department
							.slice(0, index)
							.concat(this.department.slice(index + 1, this.department.length));
					}
				});
				this.$refs.form.setFieldsValue({
					department_data: JSON.stringify(this.department)
				});
			},
            edit_department(params,index){
                this.index = index
                this.form_data_seo.title = '编辑部门'
                this.form_data_seo.list.forEach(item=>{
                    if(item.name == 'department_id'){
                        item.options.initialValue = params.department_id
                    }else if(item.name == 'role_id'){
                        item.options.initialValue = params.role_id
                    }
                })
				this.visible = true;
            },
			handleChange(data) {

			},
			handleReset() {
				this.form.resetFields();
			},
			handleSubmit(e) {
				let value = e.values

				let obj = {
					...value,
					// become_time: this.$method.get_time(value.become_time, 'YYYY-MM-DD'),
					// entry_time: this.$method.get_time(value.entry_time, 'YYYY-MM-DD'),
					// leave_time: this.$method.get_time(value.leave_time, 'YYYY-MM-DD'),

				}

				try {
					modifyUser({
						data: obj,
						info: true,
					}).then(res => {
						this.$router.go(-1)
					}).catch(error => {

					})

				} catch {

				}
			},
			submit(e) {
				let data = e.values;
				// this.form_data_seo.list.forEach(item => {
				//     if (item.name == "role_id") {
				//         item.list.forEach(vo => {
				//             if (vo.key == data.role_id) {
				//                 data.role_name = vo.value;
				//             }
				//         });
				//     }
				// });
				//获取部门
				let department_name = this.oneselfMenu(this.form_data_seo.list[0].treeData, data.department_id)
				this.name = department_name[0].name
				//获取岗位
				let role_name = this.oneselfMenu(this.form_data_seo.list[1].treeData, data.role_id)
                if(this.index == -1){
                    data.department_name = this.name;
                    data.role_name = role_name[0].name
                    this.department.push(data);
                }else{
                    this.department[this.index].department_name = this.name
                    this.department[this.index].role_name = role_name[0].name
                    this.department[this.index].department_id = data.department_id
                    this.department[this.index].role_id = data.role_id
                }
				this.$refs.form.setFieldsValue({
					department_data: JSON.stringify(this.department)
				});
                this.index = -1
				this.visible = false;
			},
			untie_wx_mini() {
				cancelBinding({
					data: {
						id: this.$route.query.id,
					},
					info: true,
				}).then(res => {
					this.get_list()
				})
			},
			oneselfMenu(arr, id) {
				var temp = []
				var forFn = function(list, id) {
					for (var i = 0; i < list.length; i++) {
						var item = list[i]
						if (item.id == id) {
							temp.unshift(item)
							break
						} else {
							if (item.sub) {
								forFn(item.sub, id)
							}
						}
					}
				}
				forFn(arr, id)
				return temp
			},
		}
	};
</script>
<style lang="less">
	.department {
		border: 1px solid #d9d9d9;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 10px;

		.time_del {
			display: block;
			padding-right: 10px;
			min-width: 70px;
		}
        .operson_box{
            display: flex;
            align-items: center;
        }
		.department_item {
			div {
				padding-left: 10px;
			}
		}
	}
</style>